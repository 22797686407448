/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// You can delete this file if you're not using it
import "./src/styles/global.css"
// require('prismjs/themes/prism-twilight.css')
require("./src/styles/reset.css")
require("./src/styles/prism-style.css")
