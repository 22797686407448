// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-code-pen-code-pen-js": () => import("../src/templates/CodePen/CodePen.js" /* webpackChunkName: "component---src-templates-code-pen-code-pen-js" */),
  "component---src-templates-articles-articles-template-js": () => import("../src/templates/Articles/ArticlesTemplate.js" /* webpackChunkName: "component---src-templates-articles-articles-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-articles-js": () => import("../src/pages/articles/Articles.js" /* webpackChunkName: "component---src-pages-articles-articles-js" */),
  "component---src-pages-articles-index-js": () => import("../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-home-index-js": () => import("../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

